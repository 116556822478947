<template>
    <main class="main">
        <div class="section section--no-pt">
            <bread-crumbs :pages-array="pagesObjects" :withNdProp="false" />
            <div class="container-l">
                <div class="inner">
                    <div class="heading">
                        <div class="heading-section">
                            <h2>{{object.name}}</h2>
                        </div>
                    </div>
                </div>
                <div class="object-gallery" style="min-height: 53rem">
                     <div class="object-gallery-wrapper">
                        <div class="object-gallery-thumbs">
                            <div class="swiper-container slider slider--object-gallery-thumbs swiper-container-initialized swiper-container-vertical swiper-container-free-mode swiper-container-thumbs">
                                <div class="swiper-wrapper">
                                    <div 
                                        v-for="(slide, idx) in object.imgs"
                                        :key="idx"
                                        class="swiper-slide"
                                        @click="setCurrentImgIndex(idx)"
                                    >
                                        <img :src="slide.link" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="object-gallery-slider">
                            <div class="swiper-container slider slider--object-gallery">
                                <div 
                                    v-if="object.imgs && Array.isArray(object.imgs)"
                                    class="swiper-wrapper"
                                >
                                    <div 
                                        v-for="(slide, idx) in object.imgs.slice(currentImgIndex, currentImgIndex+1)"
                                        :key="idx + 'a'"
                                        class="swiper-slide"
                                    >
                                        <img :src="slide.link" alt="">
                                    </div>
                                </div>
                                <div class="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                    <div class="object-gallery-description">
                        <h4>Карточка объекта</h4>
                        <p><b>Объект:</b> {{object.name}}</p>
                        <p><b>Материалы:</b> {{object.material}}</p>
                        <p><b>Местонахождение:</b>{{object.location}}</p>
                        <p><b>Объем:</b>{{object.volume}}</p>
                        <p><b>Выполненные работы:</b> {{object.works}}</p>
                        <a 
                            class="button button--primary"  
                            @click="openModalApp"
                        >
                            Отправить заявку
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section--no-pt">
            <div class="container-l">
                <div class="inner">
                    <div class="heading">
                        <div class="heading-section">
                            <h2>Другие объекты</h2>
                        </div>
                        <div class="heading-aside">
                            <a 
                                class="button button--default" 
                                @click="push('objects')"
                            >
                                Смотреть все
                            </a>
                        </div>
                    </div>
                </div>
                <ul class="list list--flex layout-objects">
                    <li 
                        class="list-item one_object_in_list" 
                        v-for="(obj, idx) in objectsCat" 
                        :key="idx"
                    >
                        <a 
                            class="object" 
                            @click="push('objects/' + obj.link + '/' + obj.linkObj)"
                        >
                            <div class="object-image">
                                <img :src="obj.mainImg" alt=""/>
                            </div>
                            <div class="object-title">{{obj.name}}</div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <application v-if="app == true" />
    </main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

import application from '../../modals/application.vue'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'

export default {
    mixins: [Functions],
    components: {
        application,
        breadCrumbs,
    },
    data() {
        return {
            object: {},
            objectsCat:[],
            currentImgIndex: 0, 
        }
    },
    watch:{
        our_objects(){
            this.getObj()
            this.getObjectsCat()
        },
        $route(){
            this.getObj()
            this.getObjectsCat()
        }
    },
    computed: {
        ...Vuex.mapGetters({
            our_objects: 'app/our_objects',
            app:'appmodal/app',
            pagesObjects: 'app/pagesObjects',
        }),
        filteredImgs() {
            let imgs = this.object.imgs
            let array = []
            let floatIndex = this.currentImgIndex
            if (imgs && Array.isArray(imgs)) {
                array.push(imgs[floatIndex])
            }

            return array
        }
    },
    mounted(){
        if (!this.our_objects) {
            return
        }
        this.getObj()
        this.getObjectsCat()
    },
    methods: {
        ...Vuex.mapMutations({
            setApp:'appmodal/setApp',
            setHrefApp:'appmodal/setHrefApp',
        }),
        getObj(){
            if(!this.our_objects){
                return
            }
            if(this.our_objects.categories == null){
                    return
            }
            this.our_objects.categories.map((el)=>{
                if(el.link == this.$route.params.cat){
                    if(el.objects == null){
                      return
                    }
                    el.objects.map((ell)=>{
                        if(ell.linkObj == this.$route.params.key){
                            this.object = ell
                        }
                    })
                }
            })
        },
        getObjectsCat(){
            if (!this.our_objects) {
                return
            }
            if (this.our_objects.categories == null) {
                return
            }
            this.objectsCat = []
            this.our_objects.categories.map((el) => {
                if (el.link == this.$route.params.cat) {
                    if (el.objects == null) {
                      return
                    }
                    el.objects.map((ell) => {
                        if (ell.linkObj != this.object.linkObj) {
                            this.objectsCat.push(ell)
                            return
                        }
                    })
                }
            })
        },
        openModalApp(){
            this.setHrefApp(window.location.href)
            this.setApp(true) 
        },
        setCurrentImgIndex(val) {
            this.currentImgIndex = val
        },
    },
}
</script>
<style lang="css" scoped>
    .slider--object-gallery-thumbs .swiper-slide{
        height: 124px;
        margin-bottom: 20px;
    }
</style>
