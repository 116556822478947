<template>
    <div class="lk-single-order-page container-l">
        <div class="button-back-block">
            <button @click="push('lk/orders')" class="button button-default">
                <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.292892 8.70711C-0.0976315 8.31658 -0.0976315 7.68342 0.292892 7.29289L6.65685 0.928932C7.04738 0.538408 7.68054 0.538408 8.07107 0.928932C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292892 8.70711ZM21 9H1V7H21V9Z" fill="black"/>
                </svg>

                <p>Назад</p>
            </button>
            
            <div v-if="order.order_status == 'Отгружен' || order.order_status == 'Не подтвержден'" class="confirm_block_buttons">
                <button 
                    class="button button--default button_confirm"
                    @click="openConfirmModal"
                >
                    Подтвердить заказ
                </button>
                <button 
                    class="button button--default button_decline"
                    @click="openDeclineModal"
                >
                    Отклонить заказ
                </button>
            </div>
        </div>

        <div class="lk-single-order-page_content">
            <div class="left-part">
                <div>
                    <div 
                        class="order-status-button"
                        :class="[
                            order.order_status == 'Отгружен' || order.order_status == 'Оформлен' ? 'order-status-button_lightgreen' : '',
                            order.order_status == 'Готов к отгрузке' ? 'order-status-button_lightblue' : '',
                            order.order_status == 'Подтвердить отгрузку' ? 'order-status-button_yellow' : '',
                            order.order_status == 'В производстве' ? 'order-status-button_purple' : '',
                            order.order_status == 'Оплачено' || order.order_status == 'Выполнен' ? 'order-status-button_green' : '',
                            order.order_status == 'Не оплачено' || order.order_status == 'Не подтвержден' || order.order_status == 'Отклонен' ? 'order-status-button_red' : '',
                            order.order_status == 'В архиве' ? 'order-status-button_black' : '',
                            order.order_status == 'Подтвержден' ? 'order-status-button_orange' : '',
                            order.order_status == 'В обработке' ? 'order-status-button_blue' : '',
                            order.order_status == 'Черновик' || order.order_status == 'Новый' ? 'order-status-button_grey' : '',
                        ]"
                    >
                        {{ order.order_status }}
                    </div>
                </div>
                <div class="order-number">
                    <!-- <p>Заказ {{ order.counterparty_agreement }}</p> -->
                    <p>Заказ №{{ order.order_id_1c }} <br> от {{ foramtDateFromISO(order.created_at) }}</p>
                </div>
                <div class="company-name">
                    <p>{{ order.organization_name }}</p>
                </div>

                <div class="manager_header_block">
                    <p>Ответственный менеджер</p>
                </div>
                <div class="manager_subheader_block">
                    <p>{{ order.manager }}</p>
                </div>

                <div class="mid_part_row" style="width: 100%;">
                    <p class="mid_part_row-name" style="width: 50%;">Сумма с НДС</p>
                    <p class="mid_part_row-value" style="width: 50%;">{{ checkSumNds(order.sum_sell, order.sum_pay, order.sum_balance, order) }} руб.</p>
                </div>
                <div class="mid_part_row" style="width: 100%;">
                    <p class="mid_part_row-name" style="width: 50%;">Сумма оплачено</p>
                    <p class="mid_part_row-value" style="width: 50%;">{{ order.sum_pay ? getSumWithSpaces(order.sum_pay) : 0 }} руб.</p>
                </div>
                <div style="width: 100%;" v-if="((order.order_status == 'Черновик' || order.order_status == 'Новый') && checkSumNds(order.sum_sell, order.sum_pay, order.sum_balance, order) != 0) || getSumWithSpaces(order.sum_balance) != 0" class="mid_part_row">
                    <p class="mid_part_row-name" style="width: 50%;">Не оплачено</p>
                    <p class="mid_part_row-value" style="width: 50%;">{{ (order.order_status == 'Черновик' || order.order_status == 'Новый') ? getOrderSumBalance(order) : getSumWithSpaces(order.sum_balance) }} руб.</p>
                </div>
            </div>
            <div class="col-3 right-part">
                <div 
                    v-if="!isEmptyElement(order.links_certificates)" 
                    class="single_link"
                    @click="openDownloadWindow(order.links_certificates[0])"
                >
                    <p>Документы</p>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z" fill="black"/>
                    </svg>
                </div>
                <div 
                    v-if="!isEmptyElement(order.links_certificates)" 
                    class="single_link"
                    @click="openDownloadWindow(order.links_certificates[0])"
                >
                    <p>Паспорта качества</p>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z" fill="black"/>
                    </svg>
                </div>
                <div 
                    v-if="!isEmptyElement(order.links_specifications)" 
                    class="single_link"
                    @click="openDownloadWindow(order.links_specifications[0])"
                >
                    <p>Сформировать/Скачать спецификацию</p>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z" fill="black"/>
                    </svg>
                </div>
                <div 
                    v-if="!isEmptyElement(order.links_payment)" 
                    class="single_link"
                    @click="openDownloadWindow(order.links_payment[0])"
                >
                    <p>Сформировать/Скачать счет</p>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z" fill="black"/>
                    </svg>
                </div>
                <div 
                    v-if="!isEmptyElement(order.links_shipping_documents)" 
                    class="single_link"
                    @click="openDownloadWindow(order.links_shipping_documents[0])"
                >
                    <p>Отгрузочные документы</p>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z" fill="black"/>
                    </svg>
                </div>
                
                <button 
                    v-if="order.positions"
                    class="button button-default"
                    @click="repeatOrder"
                >
                    Повторить заказ
                </button>
            </div>
        </div>
        <div class="order_details_block">
            <div class="buttons_block">
                <button 
                    class="button button--default"
                    :class="activeButton == 'Заказ' ? '-active' : ''"
                    @click="activeButton = 'Заказ'"
                >
                    Заказ
                </button>

                <button 
                    v-if="inProduction.length != 0"
                    class="button button--default"
                    :class="activeButton == 'В производстве' ? '-active' : ''" 
                    @click="activeButton = 'В производстве'"   
                >
                    В производстве
                </button>
                <button 
                    v-if="inStock.length != 0"
                    class="button button--default"
                    :class="activeButton == 'На складе' ? '-active' : ''"    
                    @click="activeButton = 'На складе'"
                >
                    На складе
                </button>
                <button 
                    v-if="inShipped.length != 0"
                    class="button button--default"
                    :class="activeButton == 'Отгружены' ? '-active' : ''"   
                    @click="activeButton = 'Отгружены'"
                >
                    Отгружены
                </button>
                <button 
                    v-if="inWork.length != 0"
                    class="button button--default"
                    :class="activeButton == 'В работе' ? '-active' : ''"   
                    @click="activeButton = 'В работе'"
                >
                    В работе
                </button>
                <button 
                    v-if="listHistoryMessages.length != 0"
                    class="button button--default"
                    :class="activeButton == 'История' ? '-active' : ''"   
                    @click="activeButton = 'История'"
                >
                    История изменений статусов
                </button>                
            </div>

            <div v-if="activeButton == 'Заказ'" class="order-table">
                <div class="order-table_headers">
                    <p style="width: 23%;">Состав по номенклатуре</p>
                    <p style="width: 9%; text-align: center;">Ед.изм.</p>
                    <p style="width: 9%; text-align: center;">Кол-во</p>
                    <p style="width: 16%; text-align: center;">В производстве</p>
                    <p style="width: 10%; text-align: center;">На складе</p>
                    <p style="width: 9%; text-align: center;">Отгружены</p>
                    <p style="width: 9%; text-align: center;">Остаток</p>
                    <p style="width: 9%; text-align: center;">Цена</p>
                    <p style="width: 9%; text-align: center;">Сумма</p>
                </div>
                <div class="order-table_rows">
                    <div v-if="!isEmptyObject(positionsMap)">
                        <div 
                            v-for="position, idx in positionsMap"
                            :key="idx"
                            class="order-table_rows-single"
                            :style="idx % 2 !== 1 ? 'background-color: #FAFAFA;' : ''"
                        >
                            <div class="details">
                                <div style="width: 23%; font-weight: 700;">{{ position.name }}</div>
                                <div style="width: 9%; text-align: center;">{{ position.base_unit_of_measure }}</div>
                                <div style="width: 9%; text-align: center;">{{ position.count_base_unit_of_measure }}</div>
                                <div style="width: 16%; text-align: center;">{{ position.amount_in_progress ? position.amount_in_progress : 0 }}</div>
                                <div style="width: 10%; text-align: center;">{{ position.amount_in_stock ? position.amount_in_stock : 0 }}</div>
                                <div style="width: 9%; text-align: center;">{{ position.amount_shipped ? position.amount_shipped : 0 }}</div>
                                <div style="width: 9%; text-align: center;">{{ position.amount_balance ? position.amount_balance : 0 }}</div>
                                <div style="width: 9%; text-align: center;">{{ position.price }}</div>
                                <div style="width: 9%; text-align: center; font-weight: 700;">{{ position.sell }}</div>
                            </div>
    
                            <div @click="changeOpenDetails(idx)" class="order-table_bottom">
                                <svg width="1290" height="2" style="padding-left: 4px;" viewBox="0 0 1290 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="1290" y1="1" y2="1" stroke="black" stroke-width="2"/>
                                </svg>
    
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1_23537)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7083 15.7064C12.5208 15.8938 12.2665 15.9992 12.0013 15.9992C11.7362 15.9992 11.4818 15.8938 11.2943 15.7064L5.63732 10.0494C5.54181 9.95712 5.46563 9.84678 5.41322 9.72477C5.36081 9.60277 5.33322 9.47155 5.33207 9.33877C5.33092 9.20599 5.35622 9.07431 5.4065 8.95141C5.45678 8.82852 5.53103 8.71686 5.62492 8.62297C5.71882 8.52908 5.83047 8.45483 5.95337 8.40454C6.07626 8.35426 6.20794 8.32896 6.34072 8.33012C6.4735 8.33127 6.60472 8.35886 6.72672 8.41126C6.84873 8.46367 6.95907 8.53986 7.05132 8.63537L12.0013 13.5854L16.9513 8.63537C17.1399 8.45321 17.3925 8.35241 17.6547 8.35469C17.9169 8.35697 18.1677 8.46214 18.3531 8.64755C18.5385 8.83296 18.6437 9.08377 18.646 9.34597C18.6483 9.60816 18.5475 9.86076 18.3653 10.0494L12.7083 15.7064Z" fill="black"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_23537">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
    
                                <p style="width: 14rem;">Детали заказа</p>
                            </div>
    
                            <div v-if="indexShowestDetails == idx" class="subpositions_details" style="background-color: #fff;">
                                <div 
                                    v-for="subonce, index in position.details"
                                    :key="index"
                                    class="subpositions_details_once"
                                >
                                    <div style="width: 23%;">{{ subonce.name }}</div>
                                    <div style="width: 9%; text-align: center;">{{ subonce.base_unit_of_measure }}</div>
                                    <div style="width: 9%; text-align: center;">{{ subonce.count_base_unit_of_measure }}</div>
                                    <div style="width: 16%; text-align: center;">{{ subonce.amount_in_progress ? subonce.amount_in_progress : 0 }}</div>
                                    <div style="width: 10%; text-align: center;">{{ subonce.amount_in_stock ? subonce.amount_in_stock : 0 }}</div>
                                    <div style="width: 9%; text-align: center;">{{ subonce.amount_shipped ? subonce.amount_shipped : 0 }}</div>
                                    <div style="width: 9%; text-align: center;">{{ subonce.amount_balance ? subonce.amount_balance : 0 }}</div>
                                    <div style="width: 9%; text-align: center;">{{ subonce.price }}</div>
                                    <div style="width: 9%; text-align: center; font-weight: 700;">{{ subonce.sell }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <div 
                            v-for="position, idx in order.positions"
                            :key="idx"
                            class="order-table_rows-single"
                            :style="idx % 2 !== 1 ? 'background-color: #FAFAFA;' : ''"
                        >
                            <div class="details">
                                <div style="width: 19%; font-weight: 700;">{{ getFullNamePosition(position) }}</div>
                                <div style="width: 10%; text-align: center;">{{ position.base_unit_of_measure }}</div>
                                <div style="width: 10%; text-align: center;">{{ position.count_base_unit_of_measure }}</div>
                                <div style="width: 11%; text-align: center;">{{ position.amount_in_progress ? position.amount_in_progress : 0 }}</div>
                                <div style="width: 10%; text-align: center;">{{ position.amount_in_stock ? position.amount_in_stock : 0 }}</div>
                                <div style="width: 10%; text-align: center;">{{ position.amount_shipped ? position.amount_shipped : 0 }}</div>
                                <div style="width: 10%; text-align: center;">{{ position.amount_balance ? position.amount_balance : 0 }}</div>
                                <div style="width: 10%; text-align: center;">{{ position.price }}</div>
                                <div style="width: 10%; text-align: center; font-weight: 700;">{{ position.sell }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="activeButton == 'В производстве'" class="order-table">
                <div class="order-table_headers">
                    <p style="width: 10%; text-align: center;">Номер документа</p>

                    <div class="sub_table" style="width: 70%;">
                        <p style="width: 44%; text-align: center;">Состав по номенклатуре</p>
                        <p style="width: 14%; text-align: center;">Ед.изм.</p>
                        <p style="width: 14%; text-align: center;">Кол-во</p>
                        <p style="width: 14%; text-align: center;">Дата по графику</p>
                        <p style="width: 14%; text-align: center;">Подразделение</p>
                    </div>

                    <p style="width: 10%; text-align: center;">Сумма</p>
                    <p style="width: 10%; text-align: center;">Доставка Дата</p>
                </div>
                <div class="order-table_rows">
                    <div 
                        v-for="position, idx in inProduction"
                        :key="idx"
                        class="order-table_rows-single"
                    >
                        <div class="details">
                            <div style="width: 10%; font-weight: 700;">{{ position.document_number }}</div>
                            
                            <div 
                                v-for="sub, idx in position.sub_table"
                                :key="idx" 
                                class="sub_table"
                                style="width: 70%;"
                            >
                                <div style="width: 44%;">{{ JSON.parse(sub).nomenclature }}</div>
                                <div style="width: 14%; text-align: center;">{{ JSON.parse(sub).unit }}</div>
                                <div style="width: 14%; text-align: center;">{{ JSON.parse(sub).amount }}</div>
                                <div style="width: 14%; text-align: center;">{{ getParsedDate(JSON.parse(sub).scheduled_date) }}</div>
                                <div style="width: 14%; text-align: center;">{{ JSON.parse(sub).subdivision }}</div>
                            </div>
                            <div style="width: 10%; text-align: center;">{{ position.sum }}</div>
                            <div style="width: 10%; text-align: center;">{{ getTextDeliveryType(position.delivery_type) }} <br> {{ getParsedDate(position.delivery_at) }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="activeButton == 'На складе'" class="order-table">
                <div class="order-table_headers">
                    <p style="width: 10%; text-align: center;">Номер документа</p>

                    <div class="sub_table" style="width: 70%;">
                        <p style="width: 52%; text-align: center;">Состав по номенклатуре</p>
                        <p style="width: 16%; text-align: center;">Ед.изм.</p>
                        <p style="width: 16%; text-align: center;">На складе</p>
                        <p style="width: 16%; text-align: center;">Площадка</p>
                    </div>

                    <p style="width: 10%; text-align: center;">Сумма</p>
                    <p style="width: 10%; text-align: center;">Доставка Дата</p>
                </div>
                <div class="order-table_rows">
                    <div 
                        v-for="position, idx in inStock"
                        :key="idx"
                        class="order-table_rows-single"
                    >
                        <div class="details">
                            <div style="width: 10%; font-weight: 700;">{{ position.document_number }}</div>
                            
                            <div style="width: 70%;">
                                <div 
                                    v-for="sub, idx in position.sub_table"
                                    :key="idx" 
                                    class="sub_table"
                                    style="width: 100%;"
                                >
                                    <div style="width: 52%;">{{ JSON.parse(sub).nomenclature }}</div>
                                    <div style="width: 16%; text-align: center;">{{ JSON.parse(sub).unit }}</div>
                                    <div style="width: 16%; text-align: center;">{{ JSON.parse(sub).amount }}</div>
                                    <div style="width: 16%; text-align: center;">{{ JSON.parse(sub).subdivision }}</div>
                                </div>
                            </div>
                            <div style="width: 10%; text-align: center;">{{ position.sum }}</div>
                            <div style="width: 10%; text-align: center;">{{ getTextDeliveryType(position.delivery_type) }} <br> {{ getParsedDate(position.delivery_at) }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="activeButton == 'Отгружены'" class="order-table">
                <div class="order-table_headers">
                    <p style="width: 10%; text-align: center;">Номер документа</p>

                    <div class="sub_table" style="width: 60%;">
                        <p style="width: 60%; text-align: center;">Состав по номенклатуре</p>
                        <p style="width: 20%; text-align: center;">Ед.изм.</p>
                        <p style="width: 20%; text-align: center;">На складе</p>
                    </div>

                    <p style="width: 10%; text-align: center;">Сумма</p>
                    <p style="width: 10%; text-align: center;">Площадка</p>
                    <p style="width: 10%; text-align: center;">Доставка Дата</p>
                </div>
                <div class="order-table_rows">
                    <div 
                        v-for="position, idx in inShipped"
                        :key="idx"
                        class="order-table_rows-single"
                    >
                        <div class="details">
                            <div style="width: 10%; font-weight: 700;">{{ position.document_number }}</div>
                            <div style="width: 60%;">
                                <div 
                                    v-for="sub, idx in position.sub_table"
                                    :key="idx" 
                                    class="sub_table"
                                    style="width: 100%;"
                                >
                                    <div style="width: 60%;">{{ JSON.parse(sub).nomenclature }}</div>
                                    <div style="width: 20%; text-align: center;">{{ JSON.parse(sub).unit }}</div>
                                    <div style="width: 20%; text-align: center;">{{ JSON.parse(sub).amount }}</div>
                                </div>
                            </div>
                            <div style="width: 10%; text-align: center;">{{ position.sum }}</div>
                            <div style="width: 10%; text-align: center;">{{ position.subdivision }}</div>
                            <div style="width: 10%; text-align: center;">{{ getTextDeliveryType(position.delivery_type) }} <br> {{ getParsedDate(position.delivery_at) }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="activeButton == 'В работе'" class="order-table">
                <div class="order-table_headers">
                    <div class="sub_table" style="width: 90%;">
                        <p style="width: 60%; text-align: center;">Состав по номенклатуре</p>
                        <p style="width: 10%; text-align: center;">Ед.изм.</p>
                        <p style="width: 10%; text-align: center;">Остаток</p>
                        <p style="width: 10%; text-align: center;">Цена</p>
                        <p style="width: 10%; text-align: center;">Сумма</p>
                    </div>
                    <p style="width: 10%; text-align: center;">Доставка Дата</p>
                </div>
                <div class="order-table_rows">
                    <div 
                        v-for="position, idx in inWork"
                        :key="idx"
                        class="order-table_rows-single"
                    >
                        <div style="width: 90%;">
                            <div 
                                v-for="sub, idx in position.sub_table"
                                :key="idx" 
                                class="sub_table"
                                style="width: 100%;"
                            >
                                <div style="width: 60%;">{{ JSON.parse(sub).nomenclature }}</div>
                                <div style="width: 10%; text-align: center;">{{ JSON.parse(sub).unit }}</div>
                                <div style="width: 10%; text-align: center;">{{ JSON.parse(sub).amount }}</div>
                                <div style="width: 10%; text-align: center;">{{ JSON.parse(sub).price }}</div>
                                <div style="width: 10%; text-align: center;">{{ JSON.parse(sub).sum }}</div>
                            </div>
                        </div>
                        <div style="width: 10%; text-align: center;">{{ getTextDeliveryType(position.delivery_type) }} <br> {{ getParsedDate(position.delivery_at) }}</div>
                    </div>
                </div>
            </div>

            <div v-if="activeButton == 'История'" class="order-history-list">
                <div 
                    class="order-history-once" 
                    v-for="history in listHistoryMessages"
                    :key="history.id"
                >
                    <p class="order-history-date">{{ foramtDateFromISO(history.created_at, '.') }}</p>
                    <p class="order-history-info">Изменение статуса заказа:</p>
                    <p class="order-history-description">{{ JSON.parse(history.data).description }}</p>
                </div>
            </div>
        </div>
        <error-repeat-order 
            v-if="isShowModalError" 
            @closeModal="closeModal"
        />
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import errorRepeatOrder from '@/components/modals/errorRepeatOrder'

export default {
    mixins: [Functions],
    components: {
        errorRepeatOrder,
    },
    data() {
        return {
            activeButton: 'Заказ',
            isShowModalError: false,

            indexShowestDetails: null,

            inProduction: [],
            inStock: [],
            inShipped: [],
            inWork: [],
            positionsMap: {},
            nomenclaturePositionsMap: {},
            nomenclaturePositions: [],
            parents: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            order: 'basket/currentOrder',
            basketOrder: 'basket/order',
            yandexCloud: 'app/yandexCloud',
            userCompanyId: 'auth/userCompanyId',
            user: 'auth/user',
            region: 'auth/city',
            messages: 'notif/messages',
        }),
        routeId() {
            return this.$route.params.id
        },
        listHistoryMessages() {
            let array = []
            this.messages.map((element) => {
                let id = null
                let parsed = null
                if (element.data) {
                    parsed = JSON.parse(element.data)
                    id = parsed.id
                }
                if (id == this.routeId) {
                    array.push(element)
                }
            })
            return array
        },
    },
    mounted() {
        this.getOne(Number(this.routeId))
            .then(() => {
                this.getTablesOfOrder()
                this.getPositionsNomenclature()
            })

        this.checkQuery()
    },
    methods: {
        ...Vuex.mapMutations({
            setShowConfirmOrderModal: 'basket/setShowConfirmOrderModal',
            setShowDeclineOrderModal: 'basket/setShowDeclineOrderModal',
            setShowBasketNotEmpty: 'app/setShowBasketNotEmpty',
            setTypeConfirmOrder: 'basket/setTypeConfirmOrder',
        }),
        ...Vuex.mapActions({
            getOrder: 'basket/get',
            getStatusOrderInProduction: 'reports/getStatusOrderInProduction',
            getStatusOrderInStock: 'reports/getStatusOrderInStock',
            getStatusOrderInShipped: 'reports/getStatusOrderInShipped',
            getStatusOrderInWork: 'reports/getStatusOrderInWork',
            copyOrder: 'basket/copyOrder',
            getOne: 'basket/getOne',
            searchCatalog: 'catalog/searchNavbar',
        }),
        checkQuery() {
            let params = this.$route.query
            let history_param = null
            if (params) {
                history_param = params.history
            }
            if (history_param) {
                this.activeButton = 'История'
            }
        },
        getTablesOfOrder() {
            if (!this.order.order_id_1c) {
                return
            }
            this.getStatusOrderInProduction(this.order.order_id_1c)
                .then((response) => {
                    this.inProduction = response.data
                })

            this.getStatusOrderInStock(this.order.order_id_1c)
                .then((response) => {
                    this.inStock = response.data
                })

            this.getStatusOrderInShipped(this.order.order_id_1c)
                .then((response) => {
                    this.inShipped = response.data
                })

            this.getStatusOrderInWork(this.order.order_id_1c)
                .then((response) => {
                    this.inWork = response.data
                })
        },
        getSumWithSpaces(sum) {
            if (sum == 0 || sum == null) {
                return 0
            }
            let parts = sum.toString().split(".")
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            return parts.join(".")
        },
        getTextDeliveryType(type) {
            let val = null
            if (type == 10) {
                val = 'Самовывоз'
            }else if (type == 20) {
                val = 'Доставка'
            }
            return val
        },
        getParsedDate(date) {
            if (!date) {
                return null
            }
            let parsed = date.split('-')
            let parsedDay = parsed[2].split('T')
            return parsedDay[0] + '-' + parsed[1] + '-' + parsed[0]
        },
        getFirstPositionName(name) {
            let splited = name.split(' ')
            if (splited[0] == '' || splited[0] == ' ') {
                return splited[1] + ' ' + splited[2]
            }else{
                return splited[0] + ' ' + splited[1]
            }
        },
        getFullNamePosition(pos) {
            let full_name = ''
            if (this.getFirstPositionName(pos.name)) {
                full_name += this.getFirstPositionName(pos.name)
            }
            if (pos.length) {
                full_name += ', Длина: ' + pos.length
            }
            if (pos.coating) {
                full_name += ', ' + pos.coating
            }
            if (pos.color_inner) {
                full_name += ', Цвет: ' + pos.color_inner
            }else if (pos.color_external) {
                full_name += ', Цвет: ' + pos.color_external
            }
            return full_name
        },
        openConfirmModal() {
            if (this.order.order_status == 'Отгружен') {
                this.setTypeConfirmOrder(20)
            }else{
                this.setTypeConfirmOrder(10)
            }
            this.setShowConfirmOrderModal(true)
        },
        openDeclineModal() {
            this.setShowDeclineOrderModal(true)
        },
        openDownloadWindow(str) {
            window.open(this.yandexCloud + str)
        },
        repeatOrder() {
            if (this.basketOrder.positions.length > 0) {
                this.setShowBasketNotEmpty(true)
                return
            }
            
            this.copyOrder({id: this.order.id, company_id: Number(this.userCompanyId)})
                .then((response) => {
                    localStorage.setItem('mayakOrderId', JSON.stringify(response.data.id))
                    this.push('basket')
                })
                .catch(() => {
                    this.openModal()
                })
        },
        openModal() {
			this.isShowModalError = true
		},
		closeModal() {
            this.isShowModalError = false
        },
        checkSumNds(sell, pay, balance, order) {
            let value = 0
            if (sell == null || sell == 0 || sell == '') {
                if (balance == null || balance == 0 || balance == '') {
                    balance = this.getOrderSumBalance(order)
                }
                if (pay == null || pay == 0 || pay == '') {
                    value = balance
                }else{
                    value = pay + balance
                }
            }else{
                value = sell
            }
            return this.getSumWithSpaces(value)
        },
        getOrderSumBalance(order) {
            let balance = 0
            let fixedPrice = 0
            if (order.positions != null && order.positions.length != 0) {
                order.positions.map((element) => {
                    balance += element.sell
                })
                
                if (balance) {
                    fixedPrice = ( (balance.toString().includes('.')) ? (balance.toString().split('.').pop().length) : (0) )
                }

                balance = (fixedPrice >= 2 ? balance.toFixed(2) : balance)
            }
            return this.getSumWithSpaces(balance)
        },
        getPositionsNomenclature() {
            let array = []
            let root_id = null
            if (!this.order.positions) {
                return
            }
            this.order.positions.map((element) => {
                array.push(element.id_1c)
                if (!root_id) {
                    root_id = element.root_id
                }
            })
            if (!root_id) {
                root_id = "H2016"
            }
            this.searchCatalog({
                id_1cs: array,
                root_id: root_id,
            })
                .then((response) => {
                    this.nomenclaturePositions = response.data
                    this.setNomanclaturePositionsMap()
                    if (response.data.length != 0) {
                        this.setPositionsMap()
                    }
                })
        },
        setNomanclaturePositionsMap() {
            this.nomenclaturePositions.map((element) => {
                this.$set(this.nomenclaturePositionsMap, element.id_1c, element.parent_id )
            })
        },
        setPositionsMap() {
            this.nomenclaturePositions.map((element) => {
                let object = {
                    id_1c: element.id_1c,
                    name: element.name,
                    base_unit_of_measure: null,
                    count_base_unit_of_measure: null,
                    amount_in_progress: null,
                    amount_in_stock: null,
                    amount_shipped: null,
                    amount_balance: null,
                    price: null,
                    sell: null,
                    details: [],
                }
                this.$set(this.positionsMap, element.id_1c, object)
            })
            this.setPositionsInMap()
        },
        setPositionsInMap() {
            this.order.positions.map((element) => {
                this.positionsMap[element.id_1c].details.push(element)
                this.positionsMap[element.id_1c].base_unit_of_measure = element.base_unit_of_measure
                if (!this.positionsMap[element.id_1c].count_base_unit_of_measure) {
                    this.positionsMap[element.id_1c].count_base_unit_of_measure = element.count_base_unit_of_measure
                }else{
                    this.positionsMap[element.id_1c].count_base_unit_of_measure += element.count_base_unit_of_measure
                }
                if (!this.positionsMap[element.id_1c].amount_in_progress) {
                    this.positionsMap[element.id_1c].amount_in_progress = element.amount_in_progress
                }else{
                    this.positionsMap[element.id_1c].amount_in_progress += element.amount_in_progress
                }
                if (!this.positionsMap[element.id_1c].amount_in_stock) {
                    this.positionsMap[element.id_1c].amount_in_stock = element.amount_in_stock
                }else{
                    this.positionsMap[element.id_1c].amount_in_stock += element.amount_in_stock
                }
                if (!this.positionsMap[element.id_1c].amount_shipped) {
                    this.positionsMap[element.id_1c].amount_shipped = element.amount_shipped
                }else{
                    this.positionsMap[element.id_1c].amount_shipped += element.amount_shipped
                }
                if (!this.positionsMap[element.id_1c].amount_balance) {
                    this.positionsMap[element.id_1c].amount_balance = element.amount_balance
                }else{
                    this.positionsMap[element.id_1c].amount_balance += element.amount_balance
                }
                this.positionsMap[element.id_1c].price = element.price
                if (!this.positionsMap[element.id_1c].sell) {
                    this.positionsMap[element.id_1c].sell = element.sell
                }else{
                    this.positionsMap[element.id_1c].sell += element.sell
                }
            })
        },
        changeOpenDetails(index) {
            if (this.indexShowestDetails == index) {
                this.indexShowestDetails = null
            }else{
                this.indexShowestDetails = index
            }
        }
    },
}
</script>