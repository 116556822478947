<template>
    <div 
        id="app" 
        :class="{'-navigation-dropdown-main-opened' : isOpenMobileNavigation, '-navigation-dropdown-sub-opened' : isOpenMobileSubNavigation}"
        @wheel="scrollPage"
        :style="isShowCreateUtmModal ? 'overflow: hidden' : 'overflow: auto'"
    >
        <div 
            v-if="layout == 'main'" 
            class="background-grid"
        >
            <div class="background-grid-outer">
                <div class="background-grid-inner" />
            </div>
        </div>
        <div class="wrapper">
            <admin-navbar v-if="layout == 'admin'" />
            <div 
                v-if="layout == 'admin'"
                class="admin-layout"  
            >
                <admin-left-menu />
                <router-view />
            </div>

            <left-menu v-if="layout == 'main'" />
            <navbar v-if="layout == 'main' && currentRoute != 'nlk'" />
            <router-view v-if="layout == 'main' && our_objects != null" />

            <vacancy-navbar v-if="layout == 'vacancy'" />
            <router-view v-if="layout == 'vacancy'" />
            <vacancy-footer v-if="layout == 'vacancy'" />

            <Footer v-if="layout == 'main'" />
        </div>

        <add-user-modal v-if="isShowAddUserModal && !isShowError500" />

        <create-utm-modal v-if="isShowCreateUtmModal && !isShowError500" />

        <delete-order-modal v-if="isShowDeleteOrderModal && !isShowError500" />
        <clean-basket-modal v-if="isShowCleanBasketModal && !isShowError500" />
        <success-registry-modal v-if="isShowSuccessRegistry && !isShowError500" />
        <sended-modal v-if="isShowSendedModal && !isShowError500" />
        <user-exist-modal v-if="isShowUserExistModal && !isShowError500" />
        <offer-registry-modal v-if="isShowOfferModal && !isShowError500" />
        <pdfPreviewModal v-if="isShowPdfPreviewModal && !isShowError500"  />

        <send-code-sms-modal v-if="isShowSendCodeSmsModal && !isShowError500"  />

        <error-auth-user v-if="isShowMessageError && !isShowError500"  />

        <success-recovery-send v-if="isShowRecoverySended && !isShowError500" />
        <error-activate-user v-if="isShowErrorActivateUser && !isShowError500" />
        <success-create-order v-if="isShowSuccessCreateOrder && !isShowError500" />
        <basket-not-empty v-if="isShowBasketNotEmpty && !isShowError500" />
        <error-500 v-if="isShowError500" />

        <callback-modal v-if="isShowCallbackModalForm" />

        <order-confirm-modal v-if="isShowConfirmOrderModal" />
        <order-decline-modal v-if="isShowDeclineOrderModal" />

        <last-confirm-order 
            v-if="isShowLastConfirmOrder"  
        />

        <confirm-company-message v-if="isShowConfirmCompanyMessage" />
    </div>
</template>
<script>
import Vuex from 'vuex'
import Navbar from "@/components/navbar"
import AdminNavbar from '@/components/admin/navbar.vue'
import leftMenu from "@/components/left-menu"
import AdminLeftMenu from '@/components/admin/left-menu.vue'
import addUserModal from '@/components/admin/pages/users/add-user-modal.vue'
import createUtmModal from '@/components/admin/pages/utm/create-utm-modal.vue'
import DeleteOrderModal from '@/components/modals/deleteOrder.vue'
import cleanBasketModal from '@/components/modals/cleanBasket.vue'
import successRegistryModal from '@/components/modals/successRegistry.vue'
import sendedModal from '@/components/modals/sended.vue'
import userExistModal from '@/components/modals/userExist.vue'
import offerRegistryModal from '@/components/modals/offerRegistry.vue'
import sendCodeSmsModal from '@/components/modals/sendCodeSms.vue'
import pdfPreviewModal from '@/components/modals/pdf-preview.vue'
import errorAuthUser from '@/components/modals/errorAuthUser.vue'
import successRecoverySend from '@/components/modals/successRecoverySended.vue'
import errorActivateUser from '@/components/modals/errorActivateUser.vue'
import successCreateOrder from '@/components/modals/successCreateOrder.vue'
import basketNotEmpty from '@/components/modals/basketNotEmpty.vue'
import error500 from '@/components/modals/error500.vue'
import callbackModal from '@/components/modals/navbar-callback.vue'

import vacancyNavbar from '@/components/navbar/vacancy_navbar.vue'
import vacancyFooter from '@/components/footer/vacancyFooter.vue'

import orderConfirmModal from '@/components/modals/confirmOrder.vue'
import orderDeclineModal from '@/components/modals/declineOrder.vue'

import lastConfirmOrder from '@/components/modals/lastConfirmOrder.vue'

import confirmCompanyMessage from '@/components/pages/lk/companies/modals/messageEmployee.vue'

import Footer from "@/components/footer"
import Functions from '@/utils/functions'
import Config from "@/config"

export default {
    mixins: [Functions],
    components: {
        Navbar,
        leftMenu,
        Footer,
        AdminNavbar,
        AdminLeftMenu,
        addUserModal,
        DeleteOrderModal,
        cleanBasketModal,
        successRegistryModal,
        sendedModal,
        userExistModal,
        offerRegistryModal,
        pdfPreviewModal,
        createUtmModal,
        errorAuthUser,
        successRecoverySend,
        errorActivateUser,
        successCreateOrder,
        basketNotEmpty,
        error500,
        sendCodeSmsModal,
        vacancyNavbar,
        vacancyFooter,
        callbackModal,

        orderConfirmModal,
        orderDeclineModal,

        lastConfirmOrder,
        confirmCompanyMessage,
    },
    data() {
        return {
            confirm_key: null,
            order_id: null,
            typeConfirm: 10,
            isShowConfirmCompanyMessage: false,
            isMessageAlreadyGetted: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            user: 'auth/user',
            layout: 'app/layout',
            our_objects: 'app/our_objects',
            isOpenMobileNavigation: 'app/isOpenMobileNavigation',
            isOpenMobileSubNavigation: 'app/isOpenMobileSubNavigation',
            searchForm: 'catalog/form',
            city: 'auth/city',
            topLevelCategory: 'dictionary/topLevelCategory',
            isShowAddUserModal: 'app/isShowAddUserModal',
            isShowDeleteOrderModal: 'app/isShowDeleteOrderModal',
            isShowSuccessRegistry: 'app/isShowSuccessRegistry',
            isShowSendedModal: 'app/isShowSendedModal',
            isShowCleanBasketModal: 'app/isShowCleanBasketModal',
            isShowUserExistModal: 'app/isShowUserExistModal',
            isShowOfferModal: 'app/isShowOfferModal',
            isShowPdfPreviewModal: 'app/isShowPdfPreviewModal',
            isShowCreateUtmModal: 'app/isShowCreateUtmModal',
            isShowSendCodeSmsModal: 'app/isShowSendCodeSmsModal',

            utmPositions: 'utm/utmPositions',
            companies: 'companies/companies',

            sourceUtmPhone: 'app/sourceUtmPhone',
            widgetCode: 'app/widgetCode',

            isShowMessageError: 'auth/isShowMessageError',

            isShowRecoverySended: 'app/isShowRecoverySended',
            isShowErrorActivateUser: 'app/isShowErrorActivateUser',
            isShowSuccessCreateOrder: 'app/isShowSuccessCreateOrder',
            isShowBasketNotEmpty: 'app/isShowBasketNotEmpty',
            isShowError500: 'app/isShowError500',
            isShowCallbackModalForm: 'app/isShowCallbackModalForm',

            isAuthenticated: 'auth/isAuthenticated',

            isShowConfirmOrderModal: 'basket/isShowConfirmOrderModal',
            isShowDeclineOrderModal: 'basket/isShowDeclineOrderModal',

            isShowLastConfirmOrder: 'basket/isShowLastConfirmOrder',
        }),
        form() {
            return Object.assign({}, this.searchForm)
        },
        windowWidth() {
            return window.innerWidth
        },
        currentRoute() {
            let routeName = ''
            if (this.$route.name) {
                routeName = this.$route.name
            }
            return routeName
        },
    },
    mounted() {
        setTimeout(() => {
            this.checkRouteOnOrder()
            this.checkCompanyConfirm()
            this.setConnectWebsocket()
        }, 1000)
    },
    created() {
        this.checkCompanyId()

        this.getUiCategories()
        this.getUiClients()
        this.getUiNetworks()
        this.getUiSlides()
        this.getUiPromotions()
        this.getUiDocuments()
        this.getUiConsultations()
        this.getUiCosts()
        this.getUiObjects()
        this.getUiPartners()
        this.getUiReasons()
        this.getUiReviews()
        this.getUiServices()
        this.getUiSupports()
        this.getUiCardAddress()

        if (this.isAuthenticated) {
            this.getCompanies()
        }
            
        this.setTitleDesciption()
        this.getJson()
            .then(() => {
                this.setWidgetCode()
            })
        this.getDictionaries()
        this.setWindowWidth()
        this.checkBasketProducts()
        this.getTopCategories()
    },
    watch: {
        city() {
            this.getCatalogCategories()
        },
        isShowCreateUtmModal(val) {
            if (val == true) {
                document.body.style.overflowY = "hidden"
            }else{
                document.body.style.overflowY = "auto"
            }
        },
        currentRoute() {
            this.setTitleDesciption()
        },
        isAuthenticated(val) {
            if (val) {
                this.getCompanies()
                    .then(() => {
                        this.checkCompanyId()
                    })

                this.initMessages()
            }
        },
    },
    methods: {
        ...Vuex.mapActions({
            getJson: 'app/getJson',
            getDictionaries: 'dictionary/getDictionaries',
            getOrder: 'basket/get',
            search: 'catalog/search',
            getTopLevelCategory: 'dictionary/getTopLevelCategory',
            getSecondCategory: 'dictionary/getSecondCategory',
            searchUtm: 'utm/search',
            getCompanies: 'companies/getCompanies',

            getUiCategories: 'ui_category/getCategories',
            getUiClients: 'ui_client/getClients',
            getUiNetworks: 'ui_network/getNetworks',
            getUiSlides: 'ui_slide/getSlides',
            getUiPromotions: 'ui_promotion/getPromotions',
            getUiDocuments: 'ui_document/getDocuments',
            getUiConsultations: 'ui_consultation/getConsultations',
            getUiCosts: 'ui_cost/getCosts',
            getUiObjects: 'ui_object/getObjects',
            getUiPartners: 'ui_partner/getPartners',
            getUiReasons: 'ui_reason/getReasons',
            getUiReviews: 'ui_review/getReviews',
            getUiServices: 'ui_service/getServices',
            getUiSupports: 'ui_support/getSupports',
            getUiCardAddress: 'ui_card_address/getCardAddresses',

            sendEmail: 'basket/sendEmail',
            confirmWithCode: 'basket/confirmWithCode',

            confirmEmployee: 'companies/confirmEmployee',

            authMessages: 'notif/auth',
            getMessages: 'notif/getMessages',
        }),
        ...Vuex.mapMutations({
            changeCurrentWindowWidth: 'app/changeCurrentWindowWidth',
            setForm: 'catalog/setForm',
            setCity:'auth/setCity',
            setRegionCode: 'auth/setRegionCode',
            setWindowPositionScroll: 'app/setWindowPositionScroll',
            setSourceUtmPhone: 'app/setSourceUtmPhone',
            setUserCompanyId: 'auth/setUserCompanyId',

            setShowLastConfirmOrder: 'basket/setShowLastConfirmOrder',
            pushMessage: 'notif/pushMessage',
        }),
        initMessages() {
            if (this.isMessageAlreadyGetted) {
                return false
            }
            this.getMessages(this.user.id)
                .then(() => {
                    this.isMessageAlreadyGetted = true
                    setTimeout(() => {
                        this.isMessageAlreadyGetted = false
                    }, 5000)
                })
        },
        setConnectWebsocket() {
            if (!this.isAuthenticated) {
                return false
            }

            let wssUrl = Config.wssUrl

            const socket = new WebSocket(wssUrl + "/v1/ws/connect")

            let that = this

            socket.onopen = function() {
                socket.send(JSON.stringify({ user_id: that.user.id }))
            }

            socket.onmessage = function (event) {
                that.pushMessage(JSON.parse(event.data).data)
            }

            this.initMessages()
        },
        checkCompanyConfirm() {
            let route = this.$route.name
            let hash = this.$route.params.hash
            if (route != 'confirmEmployee') {
                return
            }

            this.confirmEmployee({
                hash: hash
            })
                .then(() => {
                    this.isShowConfirmCompanyMessage = true
                    setTimeout(() => {
                        this.isShowConfirmCompanyMessage = false
                    }, 2000)
                })
        },

        checkRouteOnOrder() {
            if (this.currentRoute == 'ConfirmOrder') {
                this.confirm_key = this.$route.params.key
                this.order_id = this.$route.params.key
                this.typeConfirm = 10
                this.confirmWithCode({
                    key: this.confirm_key,
                    order_id_1c: this.order_id,
                    new_status: 'Подтвержден',
                })
                .then(() => {
                    this.setShowLastConfirmOrder(true)
                    setTimeout(() => {
                        this.setShowLastConfirmOrder(false)
                        this.push('')
                    }, 2000)
                })

            }
            if (this.currentRoute == 'ConfirmDeliveryOrder') {
                this.confirm_key = this.$route.params.key
                this.order_id = this.$route.params.key
                this.typeConfirm = 20
                this.confirmWithCode({
                    key: this.confirm_key,
                    order_id_1c: this.order_id,
                    new_status: 'В производстве',
                })
                .then(() => {
                    this.setShowLastConfirmOrder(true)
                    setTimeout(() => {
                        this.setShowLastConfirmOrder(false)
                        this.push('')
                    }, 2000)
                })
            }
        },
        setTitleDesciption() {
            if (this.currentRoute == 'Home') {
                document.title = "Маяк металл каталог продукции"

                let document_description = document.querySelector('head meta[name="description"]')
                document_description.setAttribute('content', "Производство проката с покрытием, сэндвич панелей. Все для кровли и фасада в одном месте. Выгодное строительство с Маяк металл. Гарантия. Качество. Цена. Сроки")
            }

            if (this.currentRoute == 'Legals') {
                document.title = "Маяк металл обслуживание юридических лиц"

                let document_description = document.querySelector('head meta[name="description"]')
                document_description.setAttribute('content', "Маяк Металл услуги по монтажу, проектирование, разработка рабочей документации, согласование сметы и документации, каталог товаров с ценами. Цены и условия для дилеров.")
            }

            if (this.currentRoute == 'AboutCompany') {
                document.title = "МаякМеталл производитель сэндвич панелей и металлопроката"

                let document_description = document.querySelector('head meta[name="description"]')
                document_description.setAttribute('content', "Маяк металл о компании. Производство сэндвич панелей, металлопроката, фасонных элементов, штакетника, гипсокартоновые профили, гладкий лист, рулонная сталь.")
            }

            if (this.currentRoute == 'Contacts') {
                document.title = "Маяк металл офисы продаж"

                let document_description = document.querySelector('head meta[name="description"]')
                document_description.setAttribute('content', "Маяк металл адреса офисов продаж. Адреса производственных площадок в Самаре, Красноярске, Канске")
            }

            if (this.currentRoute == 'calculatorRoof') {
                document.title = "Маяк металл посчитать крышу, забор"

                let document_description = document.querySelector('head meta[name="description"]')
                document_description.setAttribute('content', "Расчет крыши, забора, водостока. Калькулятор ограждения, кровли, водосточной системы. Расчеты с ценой.")
            }

            if (this.currentRoute == 'calculatorDrainage') {
                document.title = "Маяк металл посчитать крышу, забор"

                let document_description = document.querySelector('head meta[name="description"]')
                document_description.setAttribute('content', "Расчет крыши, забора, водостока. Калькулятор ограждения, кровли, водосточной системы. Расчеты с ценой.")
            }

            if (this.currentRoute == 'calculatorFencing') {
                document.title = "Маяк металл посчитать крышу, забор"

                let document_description = document.querySelector('head meta[name="description"]')
                document_description.setAttribute('content', "Расчет крыши, забора, водостока. Калькулятор ограждения, кровли, водосточной системы. Расчеты с ценой.")
            }

            if (this.currentRoute == 'Offers') {
                document.title = "Маяк металл акции на продукции "

                let document_description = document.querySelector('head meta[name="description"]')
                document_description.setAttribute('content', "Узнать скидки на продукцию компании Маяк Металл. купить профлист со скидкой по акции.")
            }
        },
        setWidgetCode() {  
            var newScript = document.createElement('script')
            if (!this.widgetCode) {
                return 
            }
            newScript.innerHTML = this.widgetCode
            document.body.appendChild(newScript)
        },
        issetCompanyById(id) {
            let isset = false
            this.companies.map((element) => {
                if (id == element.id) {
                    isset = true
                }
            })
            return isset
        },
        checkCompanyId() {
            let mayak_company_id = JSON.parse(localStorage.getItem("mayak_company_id"))
            if (mayak_company_id == null) {
                if (this.companies && this.companies.length != 0 && this.companies[0]) {
                    localStorage.setItem('mayak_company_id', JSON.stringify(this.companies[0].id)) 
                    this.setUserCompanyId(this.companies[0].id)
                }
            }else if (mayak_company_id) {
                if (!this.issetCompanyById(mayak_company_id)) {
                    if (this.companies && this.companies.length != 0 && this.companies[0]) {
                        localStorage.setItem('mayak_company_id', JSON.stringify(this.companies[0].id)) 
                        this.setUserCompanyId(this.companies[0].id)
                    }
                }else{
                    this.setUserCompanyId(mayak_company_id)
                }
            }
        },
        scrollPage() {
            let windowPosition = {
                top: window.pageYOffset,
                left: window.pageXOffset,
                right: window.pageXOffset + document.documentElement.clientWidth,
                bottom: window.pageYOffset + document.documentElement.clientHeight
            };
            this.setWindowPositionScroll(windowPosition)
        },
        setWindowWidth() {
            this.changeCurrentWindowWidth(this.windowWidth)
        },
        checkBasketProducts() {
            if (localStorage.mayakOrderId) {
                let order_id = JSON.parse(localStorage.getItem("mayakOrderId"))
                if (order_id && order_id != 0) {
                    this.getOrder(order_id)
                }
            }
        },
        getTopCategories() {
            let region = null
            this.getTopLevelCategory()
                .then((response) => {
                    if(localStorage.mayakRegionObject && localStorage.mayakRegionObject != 'null'){
                        region = JSON.parse(localStorage.getItem("mayakRegionObject")).region
                    }
                    if (region) {
                        this.setRegionCode({region: region, array: response.data})
                    }
                })
        },
        getCatalogCategories() {
            if (!this.city) {
                return
            }
            this.getSecondCategory({ root_id: this.city, parent_id: this.city, componentFrom: 'App' })
        },
    },
}
</script>
<style lang="scss">
    html{
        scroll-behavior: smooth;
    }

    .admin-layout{
        display: flex;
    }
</style>